import React, { useEffect, useRef, useState } from 'react';
import { GALLERY_CONSTS, ProGallery } from 'pro-gallery';
import { GalleryProps, migrateOptions } from 'pro-gallery-lib';
import { LayoutFixer } from '@wix/pro-gallery-layout-fixer';
import { useSettings, useStyles } from '@wix/tpa-settings/react';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import imageSDK from '@wix/image-client-api/dist/imageClientSDK';

import type { GallerySectionViewModel } from '../../../../service-page-view-model/gallery-section-view-model/gallerySectionViewModel';
import settingsParams from '../../settingsParams';
import stylesParams from '../../stylesParams';
import { useVisibilityCheck } from '../../hooks/useVisibilityCheck';
import {
  GalleryArrowPositionOptions,
  GalleryItemSizeOptions,
  GalleryLayoutOptions,
  ImageResizeOptions,
} from '../../types';
import { useScrollBase } from '../useScrollBase';
import classes from './Gallery.scss';

type GalleryComponentProps = {
  viewModel: GallerySectionViewModel;
};

const Gallery: React.FC<GalleryComponentProps> = ({ viewModel }) => {
  const { isMobile, isEditor, isPreview, isEditorX, isViewer } =
    useEnvironment();
  const settings = useSettings();
  const styles = useStyles();
  const { shouldBeVisible } = useVisibilityCheck();
  const selectedLayout: GalleryLayoutOptions = settings.get(
    settingsParams.galleryLayout,
  );
  const isSliderImageResizeOptionCrop =
    settings.get(settingsParams.gallerySliderImageResizeOption) ===
    ImageResizeOptions.CROP;

  const galleryItemSizeOptionsToPixels = (
    galleryItemSizeOptions: GalleryItemSizeOptions,
  ) => {
    if (isMobile) {
      return 200;
    }
    switch (galleryItemSizeOptions) {
      case GalleryItemSizeOptions.LARGE:
        return 500;
      case GalleryItemSizeOptions.SMALL:
        return 150;
      case GalleryItemSizeOptions.MEDIUM:
      default:
        return 300;
    }
  };

  const getOptionsByGalleryLayoutOptions = () => {
    const isShowArrows =
      viewModel.items.length > 1 &&
      settings.get(settingsParams.gallerySliderShowArrows);
    switch (selectedLayout) {
      case GalleryLayoutOptions.MASONRY:
        return {
          galleryLayout:
            GALLERY_CONSTS.layoutParams_structure_galleryLayout.MASONRY,
          itemBorderColor: styles.get(
            stylesParams.galleryMasonryItemBorderColor,
          ).value,
          itemBorderWidth: settings.get(
            settingsParams.galleryMasonryItemBorderWidth,
          ),
          itemBorderRadius: settings.get(
            settingsParams.galleryMasonryItemRadius,
          ),
          imageMargin: settings.get(settingsParams.galleryMasonryItemSpacing),
          gallerySizeType: 'px',
          gallerySizePx: galleryItemSizeOptionsToPixels(
            settings.get(settingsParams.galleryMasonryItemSize),
          ),
          hoveringBehaviour:
            GALLERY_CONSTS.behaviourParams_item_overlay_hoveringBehaviour
              .NEVER_SHOW,
        };
      case GalleryLayoutOptions.GRID:
        return {
          galleryLayout:
            GALLERY_CONSTS.layoutParams_structure_galleryLayout.GRID,
          itemBorderColor: styles.get(stylesParams.galleryGridItemBorderColor)
            .value,
          imageMargin: settings.get(settingsParams.galleryGridItemSpacing),
          itemBorderWidth: settings.get(
            settingsParams.galleryGridItemBorderWidth,
          ),
          itemBorderRadius: settings.get(settingsParams.galleryGridItemRadius),
          gallerySizeType: 'px',
          gallerySizePx: settings.get(settingsParams.galleryGridThumbnailSize),
          hoveringBehaviour:
            GALLERY_CONSTS.behaviourParams_item_overlay_hoveringBehaviour
              .NEVER_SHOW,
        };
      case GalleryLayoutOptions.SLIDER:
      default:
        return {
          galleryLayout:
            GALLERY_CONSTS.layoutParams_structure_galleryLayout.SLIDER,
          itemBorderColor: styles.get(stylesParams.gallerySliderItemBorderColor)
            .value,
          imageMargin: settings.get(settingsParams.gallerySliderItemSpacing),
          itemBorderWidth: settings.get(
            settingsParams.gallerySliderItemBorderWidth,
          ),
          itemBorderRadius: settings.get(
            settingsParams.gallerySliderItemRadius,
          ),
          cubeType: GALLERY_CONSTS.layoutParams_crop_method.FILL,
          slideshowLoop: settings.get(settingsParams.gallerySliderLoopImages),
          isAutoSlideshow:
            !isEditor && settings.get(settingsParams.gallerySliderAutoSlide),
          autoSlideshowInterval: settings.get(
            settingsParams.gallerySliderTimeBetweenImages,
          ),
          autoSlideshowType: 'interval',
          showArrows: isShowArrows,
          arrowsSize: settings.get(settingsParams.gallerySliderArrowsSize),
          arrowsPosition:
            settings.get(settingsParams.gallerySliderArrowsPosition) ===
              GalleryArrowPositionOptions.INSIDE || !isShowArrows
              ? GALLERY_CONSTS.layoutParams_thumbnails_position.ON_GALLERY
              : GALLERY_CONSTS.layoutParams_thumbnails_position.OUTSIDE_GALLERY,
          hoveringBehaviour:
            GALLERY_CONSTS.behaviourParams_item_overlay_hoveringBehaviour
              .NEVER_SHOW,
          cubeRatio: isSliderImageResizeOptionCrop ? '1' : '100%/100%',
        };
    }
  };

  const getViewMode = () => {
    if (isViewer) {
      return GALLERY_CONSTS.viewMode.SITE;
    }
    if (isPreview) {
      return GALLERY_CONSTS.viewMode.PREVIEW;
    }
    if (isEditorX || isEditor) {
      return GALLERY_CONSTS.viewMode.EDIT;
    }
  };

  const items = viewModel.items.map(
    ({ height, width, relativeUri, altText }, i) => ({
      itemId: `sample-id-${i}`,
      mediaUrl: relativeUri,
      metaData: {
        alt: altText,
        type: 'image',
        height,
        width,
      },
    }),
  );
  if (!items.length) {
    return null;
  } else {
    const viewMode = getViewMode();
    const options = migrateOptions(getOptionsByGalleryLayoutOptions());
    const container = {
      height: Number(
        selectedLayout === GalleryLayoutOptions.SLIDER &&
          settings.get(settingsParams.gallerySliderHeight),
      ),
      width: shouldBeVisible('sidebarVisibility') ? (isMobile ? 280 : 0) : 720,
    };

    return (
      <GalleryWrapper
        items={items}
        viewMode={viewMode as Props['viewMode']}
        options={options as any}
        container={container!}
      />
    );
  }
};
const GALLERY_ID = 'service-page-gallery-section';
const createMediaUrl = ({
  item,
  originalUrl,
  requiredWidth,
  requiredHeight,
}): string => {
  // relativeUrl, sourceWidth, sourceHeight, targetWidth, targetHeight, options
  return imageSDK.getScaleToFillImageURL(
    originalUrl,
    item.metaData.width,
    item.metaData.height,
    requiredWidth,
    requiredHeight,
  );
};

type Props = Pick<GalleryProps, 'items' | 'options' | 'container' | 'viewMode'>;
export const GalleryWrapper: React.FC<Props> = ({
  items,
  container: defaultContainer,
  viewMode,
  options,
}) => {
  const [container, setContainer] = useState(defaultContainer);
  const containerRef = useRef<HTMLDivElement>(null);
  const scrollBase = useScrollBase(containerRef);
  const { isSSR } = useEnvironment();
  useEffect(() => {
    if (containerRef.current) {
      setContainer({
        width: containerRef.current.clientWidth,
        height: containerRef.current.clientHeight,
      });
    }
  }, [containerRef.current]);
  const isPrerenderMode = !container.width;
  return (
    <div
      className={classes.wixGalleryWrapper}
      style={{ width: '100%' }}
      ref={containerRef}
      data-hook="gallery-section"
    >
      <ProGallery
        id={GALLERY_ID}
        viewMode={viewMode}
        items={items}
        options={options}
        isPrerenderMode={isPrerenderMode}
        container={{ ...container, scrollBase }}
        createMediaUrl={createMediaUrl}
        totalItemsCount={items.length}
        scrollingElement={isSSR ? null : window}
      />
      {isPrerenderMode && (
        <LayoutFixer
          id={GALLERY_ID}
          items={items}
          options={options}
          isPrerenderMode={isPrerenderMode}
        />
      )}
    </div>
  );
};
GalleryWrapper.displayName = 'GalleryWrapper';

export default Gallery;
